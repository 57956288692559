<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalDelete } from "lorga-ui";
import { toRefs } from "vue";
import { IBegleitfahrtTyp } from "../api/useBegleitfahrtTypen";

const props = defineProps<{
  query: () => void;
  typ: IBegleitfahrtTyp;
}>();
const { query } = toRefs(props);

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="delete" @click="commandModalOpen = true">
    <ModalDelete
      v-model="commandModalOpen"
      submit="Löschen"
      title="Begleitfahrt Typ Löschen"
      :data="{
        action: 'begleitfahrt_typ.loeschen',
        id: typ.id,
      }"
      :request="commandRequest"
      :obj-name="typ.name"
    />
    Löschen
  </ButtonNormal>
</template>
