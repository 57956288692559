<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { useAlertStore } from "@/store/alert";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { useAuthStore } from "../store";

const userStore = useAuthStore();
const alertStore = useAlertStore();

const fields: types.FormField[] = [
  {
    label: "Aktuelle Passwort",
    name: "current_password",
    type: "password",
    required: true,
  },
  {
    label: "Neue Passwort",
    name: "new_password",
    type: "password",
    required: true,
  },
  {
    label: "Neue Passwort wiederholen",
    name: "new_password_confirm",
    type: "password",
    required: true,
  },
];

const onSuccess = () => {
  alertStore.createAlert({
    heading: "Erfolg",
    type: "success",
    message: "Passwort erfolgreich geändert",
  });
};

const { commandModalOpen, commandRequest } = useCmd(onSuccess);
</script>

<template>
  <ButtonNormal
    v-if="userStore.user"
    kind="action"
    @click="commandModalOpen = true"
  >
    <ModalCreate
      v-model="commandModalOpen"
      submit="Speichern"
      title="Passwort ändern"
      :data="{ action: 'user.change_password', user_id: userStore.user.id }"
      :fields="fields"
      :request="commandRequest"
    />
    Passwort ändern
  </ButtonNormal>
</template>
