import useClient from "@/api/client";
import { cleanUpError } from "@/api/errors";
import { types } from "lorga-ui";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { Group } from "../management/utils/userGroups";

interface IUser {
  id: string;
  email: string;
  name: string;
  groups: string[];
}

export const useAuthStore = defineStore("user", () => {
  const user = ref<IUser>();

  const setData = (data: IUser) => {
    user.value = data;
  };

  const client = useClient();
  const { request } = client.get<IUser>("/query/me/");

  const router = useRouter();

  const updateData = () => {
    return request()
      .then((r) => setData(r))
      .catch(cleanUpError)
      .catch((error: types.ICommandError) => {
        if (error.title !== "Unauthorized")
          router.push({ name: "error", query: { error: error.title } });
        else Promise.resolve();
      });
  };

  const isAuthenticated = computed(() => {
    return !!user.value;
  });

  const getLogoutUrl = () => {
    return import.meta.env.VITE_API_URL + "/logout";
  };

  const reset = () => {
    user.value = undefined;
  };

  const hasGroup = (group: Group) => {
    if (!user.value) return false;
    return user.value.groups.includes(group);
  };

  return {
    user,
    isAuthenticated,
    reset,
    updateData,
    getLogoutUrl,
    hasGroup,
  };
});
