<script setup lang="ts">
import { ButtonNormal, ModalCreate } from "lorga-ui";
import { ref, toRefs } from "vue";
import { useKunden } from "../composables/useKunden";
import { useFahrer } from "../composables/useFahrer";
import useAuftragFields from "../composables/useAuftragFields";
import useCmd from "@/composables/useCmd";
import { checkBegleitfahrten } from "../utils/begleitfahrt";
import { IAuftrag } from "../api/useDispositionsplan";
import { useBegleitfahrtTypen } from "@/features/sonstiges/api/useBegleitfahrtTypen";

const props = defineProps<{ query: () => void; auftrag: IAuftrag }>();
const { auftrag, query } = toRefs(props);

const { commandRequest, commandModalOpen } = useCmd(query.value);

const { kundenForm } = useKunden(commandModalOpen);
const { fahrerForm } = useFahrer(commandModalOpen);

const { typenSelect } = useBegleitfahrtTypen(commandModalOpen);
const { auftragFields: fields } = useAuftragFields(
  kundenForm,
  fahrerForm,
  typenSelect,
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const copyData = ref<Record<string, any>>({});

const setCopyData = () => {
  copyData.value = {
    kunde_id: auftrag.value.kunde.id,
    von: auftrag.value.abholung.name,
    nach: auftrag.value.anlieferung.name,
    abholdatum: auftrag.value.abholung.datum,
    kennzeichen: auftrag.value.fahrzeug.kennzeichen,
    fahrzeugtyp: auftrag.value.fahrzeug.fahrzeugtyp,
    fahrgestellnummer: auftrag.value.fahrzeug.fahrgestellnummer,
    mehrwertsteuerpflichtig: auftrag.value.mehrwertsteuerpflichtig,
    begleitfahrten: auftrag.value.begleitfahrten.map((b) => ({
      typ: b.typ.id,
      von: b.von.name,
      nach: b.nach.name,
      abholdatum: b.von.datum,
      anlieferdatum: b.nach.datum,
      fahrer: null,
      mehrwertsteuerpflichtig: b.mehrwertsteuerpflichtig,
    })),
  };
};

const open = () => {
  setCopyData();
  commandModalOpen.value = true;
};
</script>

<template>
  <ButtonNormal kind="action" @click="open">K</ButtonNormal>
  <ModalCreate
    v-model="commandModalOpen"
    title="Auftrag Hinzufügen"
    submit="Hinzufügen"
    :fields="fields"
    :request="commandRequest"
    :data="{
      action: 'auftrag.erstellen',
      ...copyData,
    }"
    width="max-w-4xl"
    @change="checkBegleitfahrten"
  />
</template>
