import EinstellungenDashboard from "@/features/sonstiges/views/EinstellungenDashboard.vue";
import BegleitfahrtTypen from "./views/BegleitfahrtTypen.vue";

// attention: do not add a slash in front of the paths as this will break the routing.
// For example the router will see it as a root path like '/dashboard' instead of '/whatever/dashboard'.
export const routes = [
  {
    path: "einstellungen",
    component: EinstellungenDashboard,
    name: "sonstiges-einstellungen",
  },
  {
    path: "begleitfahrt-typen",
    component: BegleitfahrtTypen,
    name: "sonstiges-begleitfahrt-typen",
  },
];
