<script setup lang="ts">
import { TableGenerator } from "lorga-ui";
import { useBegleitfahrtTypen } from "../api/useBegleitfahrtTypen";
import BegleitfahrtTypErstellen from "../actions/BegleitfahrtTypErstellen.vue";
import BegleitfahrtTypBearbeiten from "../actions/BegleitfahrtTypBearbeiten.vue";
import BegleitfahrtTypLoeschen from "../actions/BegleitfahrtTypLoeschen.vue";
import BooleanDisplay from "@/components/BooleanDisplay.vue";

const { typen, query } = useBegleitfahrtTypen();

const head = [
  { name: "Name", key: "name" },
  { name: "Soll Exportieren", key: "soll_exportieren" },
  { name: "", key: "action" },
];
</script>

<template>
  <div class="p-5 px-6 bg-slate-500">
    <TableGenerator :head="head" :data="typen">
      <template #head-action>
        <BegleitfahrtTypErstellen :query="query" />
      </template>
      <template #name="{ i }">
        <div
          :style="{
            backgroundColor: i.hintergrundfarbe,
            color: i.schriftfarbe,
          }"
          class="block px-2 py-0.5"
        >
          {{ i.name }}
        </div>
      </template>
      <template #soll_exportieren="{ i }">
        <BooleanDisplay :value="i.soll_exportieren" />
      </template>
      <template #action="{ i }">
        <BegleitfahrtTypBearbeiten :query="query" :typ="i" />
        <BegleitfahrtTypLoeschen :query="query" :typ="i" />
      </template>
    </TableGenerator>
  </div>
</template>
