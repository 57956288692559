<script setup lang="ts">
import { ButtonNormal, ModalCreate } from "lorga-ui";
import { toRefs } from "vue";
import { useKunden } from "../composables/useKunden";
import { useFahrer } from "../composables/useFahrer";
import useAuftragFields from "../composables/useAuftragFields";
import useCmd from "@/composables/useCmd";
import { checkBegleitfahrten } from "../utils/begleitfahrt";
import { useBegleitfahrtTypen } from "@/features/sonstiges/api/useBegleitfahrtTypen";

const props = defineProps<{ query: () => void; date: string }>();
const { date, query } = toRefs(props);

const { commandRequest, commandModalOpen } = useCmd(query.value);

const { kundenForm } = useKunden(commandModalOpen);
const { fahrerForm } = useFahrer(commandModalOpen);

const { typenSelect } = useBegleitfahrtTypen(commandModalOpen);
const { auftragFields: fields } = useAuftragFields(
  kundenForm,
  fahrerForm,
  typenSelect,
);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    Auftrag Hinzufügen
  </ButtonNormal>
  <ModalCreate
    ref="modal"
    v-model="commandModalOpen"
    title="Auftrag Hinzufügen"
    submit="Hinzufügen"
    :fields="fields"
    :request="commandRequest"
    :data="{
      action: 'auftrag.erstellen',
      abholdatum: date,
      mehrwertsteuerpflichtig: true,
    }"
    width="max-w-4xl"
    @change="checkBegleitfahrten"
  />
</template>
