<script setup lang="ts">
import { computed } from "vue";
import { LocationQueryRaw, RouteParams, useRoute, useRouter } from "vue-router";

const props = defineProps<{
  to?: { name: string; query?: LocationQueryRaw; params?: RouteParams };
  href?: string;
}>();

const route = useRoute();
const router = useRouter();

const isActive = computed(() => {
  if (!props.to) return false;
  const resolved = router.resolve(props.to);
  const path = resolved.path;
  if (String(route.path).includes(path)) return true;
  return false;
});
</script>

<template>
  <router-link v-if="to" class="block" :to="to">
    <div class="flex items-center">
      <div v-show="isActive" class="inline-block">&#8614;&nbsp;</div>
      <div class="inline-block"><slot :is-active="isActive" /></div>
    </div>
  </router-link>
  <a v-else-if="href" :href="href" class="block">
    <div class="flex items-center">
      <div class="inline-block"><slot /></div>
    </div>
  </a>
</template>
