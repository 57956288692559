import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { getFirstOfMonthDate } from "@/utils/date";
import { ref } from "vue";
import { z } from "zod";
import { ZAuftrag, ZBegleitfahrt } from "./useAbrechnungsplan";

export const ZPlan = z
  .object({
    auftraege: z.array(ZAuftrag),
    monate: z.record(z.string(), z.string()),
  })
  .optional();

export type IPlan = z.infer<typeof ZPlan>;
export type IAuftrag = z.infer<typeof ZAuftrag>;
export type IBegleitfahrt = z.infer<typeof ZBegleitfahrt>;

const useAbrechnungMonatsansicht = () => {
  const client = useClient();
  const plan = ref<IPlan>();

  const { request, setQueryParam, queryParams } = client.get(
    "/query/abrechnung/monatsansicht/",
  );
  if (!queryParams.value.woche) setQueryParam("monat", getFirstOfMonthDate());
  if (!queryParams.value.status_filter)
    setQueryParam("status_filter", "fehlt_noch_und_nicht_abgerechnet");
  const query = useGet(request, plan, queryParams);

  useValidation(plan, ZPlan);

  return {
    query,
    setQueryParam,
    queryParams,
    plan,
  };
};

export default useAbrechnungMonatsansicht;
