export const getFahrzeugColor = (typ: string) => {
  if (typ.includes("LTM") || typ.includes("LTC") || typ.includes("LG"))
    return "bg-amber-500/20 text-amber-900";
  return "text-gray-800";
};

export const getBegleittypColor = (typ: string) => {
  if (typ.includes("BF3")) return "bg-orange-400/20 text-red-900"; // rgb(251, 146, 60, .20)|#7f1d1d
  if (typ.includes("BF") || typ.includes("Verwaltungshelfer"))
    return "bg-red-400/20 text-red-900"; // rgb(248, 113, 113, .20)|#7f1d1d
  if (typ.includes("Hilfspolizei")) return "bg-cyan-500/20 text-cyan-900"; // rgb(6, 182, 212. 20)|#164e63
  if (typ.includes("Polizei")) return "bg-blue-500/20 text-blue-900"; // rgb(59, 130, 246, .20)|#1e3a8a
  if (typ.includes("Beifahrer")) return "bg-green-500/20 text-green-900"; // rgb(34, 197, 94, .20)|#14532d
  return "text-gray-800";
};
