export const GROUPS = [
  "disposition",
  "management",
  "buchhaltung",
  "fahrer",
  "abrechnung",
];
export const GROUPS_FORM = GROUPS.map((group) => ({
  value: group,
  name: group.charAt(0).toUpperCase() + group.slice(1),
}));
