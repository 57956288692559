<script setup lang="ts">
import { ButtonNormal, FormInput, ModalFree } from "lorga-ui";
import { computed, ref, watch } from "vue";

const props = defineProps<{
  date?: string;
}>();

const exportUrl = computed(() => {
  const u1 = import.meta.env.VITE_API_URL;
  const u2 = u1 + "/query/export/dispositionsplan/";
  const u3 = u2 + "?zeit=" + selectedDate.value;
  return u3;
});

const showModal = ref(false);

const selectedDate = ref(props.date);
watch(
  () => props.date,
  (newVal) => {
    if (!newVal) return;
    selectedDate.value = newVal;
  },
);
</script>

<template>
  <ModalFree
    v-model="showModal"
    :title="`Access Export Generieren`"
    submit="Export Generieren"
  >
    <FormInput
      v-model="selectedDate"
      type="date"
      label="Datum"
      required
      name="date"
      helptext="Es werden alle Aufträge, die KEINE Abrechnungsnummer haben, von diesem Tag exportiert."
    />
    <ButtonNormal
      kind="primary"
      class="[&>a]:no-underline mt-4 ml-auto flex justify-end"
      target="_blank"
      :href="exportUrl"
      @click="showModal = true"
    >
      Export Generieren
    </ButtonNormal>
  </ModalFree>
  <ButtonNormal
    kind="action"
    class="[&>a]:no-underline"
    target="_blank"
    @click="showModal = true"
  >
    Access Export Generieren
  </ButtonNormal>
</template>
