import AccountDashboard from "./views/AccountDashboard.vue";

// attention: do not add a slash in front of the paths as this will break the routing.
// For example the router will see it as a root path like '/dashboard' instead of '/whatever/dashboard'.
export const routes = [
  {
    path: "dashboard",
    component: AccountDashboard,
    name: "account-dashboard",
  },
];
