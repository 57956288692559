<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { toRefs } from "vue";
import { IBegleitfahrtTyp } from "../api/useBegleitfahrtTypen";

const props = defineProps<{ query: () => void; typ: IBegleitfahrtTyp }>();
const { query } = toRefs(props);

const fields: types.FormField[] = [
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Schriftfarbe",
    name: "schriftfarbe",
    type: "text",
    required: true,
  },
  {
    label: "Hintergrundfarbe",
    name: "hintergrundfarbe",
    type: "text",
    required: true,
  },
  {
    label: "Soll Exportieren",
    name: "soll_exportieren",
    type: "singlecheckbox",
  },
];

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Bearbeiten"
      title="Typ Bearbeiten"
      :data="{
        action: 'begleitfahrt_typ.bearbeiten',
        id: typ.id,
        name: typ.name,
        schriftfarbe: typ.schriftfarbe,
        hintergrundfarbe: typ.hintergrundfarbe,
        soll_exportieren: typ.soll_exportieren,
      }"
      :fields="fields"
      :request="commandRequest"
    />
    Bearbeiten
  </ButtonNormal>
</template>
