<script setup lang="ts">
import { useAuthStore } from "@/features/auth/store";
import SideNavLink from "./SideNavLink.vue";
import { Bars3Icon } from "@heroicons/vue/20/solid";

defineProps<{
  show: boolean;
  toggle: () => void;
}>();

const authStore = useAuthStore();
</script>

<template>
  <nav
    class="sticky top-0 inline-block w-[16rem] align-top bg-slate-200 print:hidden"
    :class="{ 'min-h-screen': show, 'h-6': !show }"
  >
    <div class="">
      <div
        class="flex items-center justify-between h-6 px-5 font-bold bg-slate-800 text-slate-50"
      >
        <span class="block">Gerber Logistik</span>
        <button type="button" class="focus:outline-none" @click="toggle()">
          <span class="sr-only">Menü</span>
          <Bars3Icon class="w-5 h-5" />
        </button>
      </div>
      <div v-if="show" class="px-5 py-4">
        <div class="space-y-1">
          <div class="pl-1 space-y-4">
            <div>
              <div>Disposition</div>
              <div class="pl-3">
                <SideNavLink :to="{ name: 'disposition-dashboard' }">
                  Wochenansicht
                </SideNavLink>
                <SideNavLink :to="{ name: 'disposition-liste' }">
                  Listenansicht
                </SideNavLink>
                <SideNavLink :to="{ name: 'wochenplan-dashboard' }">
                  Wochenplan
                </SideNavLink>
              </div>
            </div>
            <div>
              <div>Abrechnung</div>
              <div class="pl-3">
                <SideNavLink :to="{ name: 'abrechnung-dashboard' }">
                  Wochenansicht
                </SideNavLink>
                <SideNavLink :to="{ name: 'abrechnung-monatsansicht' }">
                  Monatsansicht
                </SideNavLink>
              </div>
            </div>
            <div>
              <div>Management</div>
              <div class="pl-3">
                <SideNavLink :to="{ name: 'management-kunden' }">
                  Kunden
                </SideNavLink>
                <SideNavLink :to="{ name: 'management-fahrer' }">
                  Fahrer
                </SideNavLink>
                <SideNavLink :to="{ name: 'management-users' }">
                  Users
                </SideNavLink>
              </div>
            </div>
            <div>
              <div>Sonstiges</div>
              <div class="pl-3">
                <SideNavLink :to="{ name: 'sonstiges-einstellungen' }">
                  Einstellungen
                </SideNavLink>
              </div>
            </div>
            <div>
              <div>Account</div>
              <div class="pl-3">
                <SideNavLink :to="{ name: 'account-dashboard' }">
                  Einstellungen
                </SideNavLink>
                <SideNavLink
                  v-if="authStore.isAuthenticated"
                  :href="authStore.getLogoutUrl()"
                >
                  Logout
                </SideNavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
