<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{ query: () => void }>();
const { query } = toRefs(props);

const fields: types.FormField[] = [
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Schriftfarbe",
    name: "schriftfarbe",
    type: "text",
    required: true,
  },
  {
    label: "Hintergrundfarbe",
    name: "hintergrundfarbe",
    type: "text",
    required: true,
  },
  {
    label: "Soll Exportieren",
    name: "soll_exportieren",
    type: "singlecheckbox",
  },
];

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Erstellen"
      title="Typ Erstellen"
      :data="{ action: 'begleitfahrt_typ.erstellen' }"
      :fields="fields"
      :request="commandRequest"
    />
    Begleitfahrt Typ Erstellen
  </ButtonNormal>
</template>
