import useClient from "@/api/client";
import useQuery from "@/composables/useQuery";
import { useValidation } from "@/composables/useValidation";
import { computed, Ref, ref, watch } from "vue";
import { z } from "zod";

const ZBegleitfahrtTyp = z.object({
  id: z.string(),
  name: z.string(),
  schriftfarbe: z.string(),
  hintergrundfarbe: z.string(),
  soll_exportieren: z.boolean(),
});
const ZBegleitfahrtTypen = z.array(ZBegleitfahrtTyp).optional();

export type IBegleitfahrtTyp = z.infer<typeof ZBegleitfahrtTyp>;

export function useBegleitfahrtTypen(doQuery?: Ref<boolean>) {
  const typen = ref<IBegleitfahrtTyp[]>();
  const client = useClient();
  const { request } = client.get("/query/begleitfahrt_typen/");

  const query = useQuery(request, typen);
  if (doQuery !== undefined) watch(doQuery, (nv) => nv && query());
  else query();

  useValidation(typen, ZBegleitfahrtTypen);

  const typenSelect = computed(() => {
    if (!typen.value) return [];
    return typen.value.map((v) => ({ name: v.name, value: v.id }));
  });

  return {
    query,
    typen,
    typenSelect,
  };
}
