import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { ref } from "vue";
import { z } from "zod";

const ZUser = z.object({
  id: z.string(),
  email: z.string().email(),
  name: z.string(),
  groups: z.array(z.string()),
});

type IUser = z.infer<typeof ZUser>;

export const useUsers = () => {
  const users = ref<IUser[]>();
  const client = useClient();
  const { request } = client.get("/query/users/");
  useValidation(users, z.array(ZUser).optional());
  const query = useGet(request, users);

  return {
    query,
    users,
  };
};
