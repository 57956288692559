<script setup lang="ts">
import { TableGenerator } from "lorga-ui";
import UserUpdate from "../actions/UserUpdate.vue";
import { useUsers } from "../api/useUsers";

const { users, query } = useUsers();

const head = [
  { name: "E-Mail", key: "email" },
  { name: "Name", key: "name" },
  { name: "Gruppen", key: "groups" },
  { name: "", key: "actions" },
];

const addUserUrl = import.meta.env.VITE_API_URL + "/signup";
</script>

<template>
  <div class="p-5 px-6 bg-slate-500">
    <TableGenerator :head="head" :data="users">
      <template #head-actions>
        <a :href="addUserUrl" target="_blank" rel="noopener">User Hinzufügen</a>
      </template>
      <template #actions="{ i }">
        <UserUpdate
          :user-id="i.id"
          :user-name="i.name"
          :query="query"
          :user-groups="i.groups"
        />
      </template>
    </TableGenerator>
  </div>
</template>
