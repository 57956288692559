<script setup lang="ts">
import SettingsBlock from "@/components/SettingsBlock.vue";
import { useAuthStore } from "../store";
import ChangePassword from "../actions/ChangePassword.vue";

const userStore = useAuthStore();
</script>

<template>
  <div class="p-10 bg-gray-500">
    <div class="grid gap-5 md:grid-cols-3">
      <SettingsBlock title="Account">
        <p>
          Name: {{ userStore.user?.name }}
          <br />
          E-Mail: {{ userStore.user?.email }}
        </p>
        <ChangePassword />
      </SettingsBlock>
    </div>
  </div>
</template>
