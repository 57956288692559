import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { ZTyp } from "@/features/disposition/api/useDispositionsplan";
import { getMondayDate } from "@/utils/date";
import { ref } from "vue";
import { z } from "zod";

export const FEHLT_NOCH = "fehlt_noch";
export const NICHT_ABGERECHNET = "nicht_abgerechnet";
export const IST_ABGERECHNET = "ist_abgerechnet";

const ZAbrechnungsstatus = z.union([
  z.literal(FEHLT_NOCH),
  z.literal(NICHT_ABGERECHNET),
  z.literal(IST_ABGERECHNET),
]);

const ZPunkt = z.object({
  name: z.string(),
  datum: z.string().nullable(),
});

const ZFahrer = z.object({
  id: z.string(),
  name: z.string(),
  ist_informiert: z.boolean(),
  hat_dokumente: z.boolean(),
  abrechnungsstatus: ZAbrechnungsstatus,
});

export const ZBegleitfahrt = z.object({
  id: z.string(),
  fahrer: ZFahrer.nullable(),
  abzurechnende_fahrer: z.array(ZFahrer),
  von: ZPunkt,
  nach: ZPunkt,
  typ_name: z.string(),
  typ: ZTyp,
});

const ZFahrzeug = z.object({
  kennzeichen: z.string(),
  fahrzeugtyp: z.string(),
  fahrer_namen: z.array(z.string()),
  fahrgestellnummer: z.string(),
  fahrer: z.array(ZFahrer),
  abzurechnende_fahrer: z.array(ZFahrer),
});

const ZKunde = z.object({
  firma: z.string(),
  id: z.string(),
});

export const ZAuftrag = z.object({
  id: z.string(),
  bemerkung: z.string(),
  abgeschlossen: z.boolean(),
  kunde: ZKunde,
  abholung: ZPunkt,
  anlieferung: ZPunkt,
  fahrzeug: ZFahrzeug,
  begleitfahrten: z.array(ZBegleitfahrt),
  abrechnungsnummer: z.string(),
  alle_fahrer_ids: z.array(z.string()),
});

export const ZPlan = z
  .object({
    auftraege: z.record(z.array(ZAuftrag)),
    zeit: z.string(),
    wochen: z.record(z.number()),
    jahr: z.number(),
    ist_abgerechnet: z.boolean(),
  })
  .optional();

export type IPlan = z.infer<typeof ZPlan>;
export type IAuftrag = z.infer<typeof ZAuftrag>;
export type IFahrer = z.infer<typeof ZFahrer>;
export type IBegleitfahrt = z.infer<typeof ZBegleitfahrt>;

const useAbrechnungsplan = () => {
  const client = useClient();
  const plan = ref<IPlan>();

  const { request, setQueryParam, queryParams } =
    client.get("/query/abrechnung/");
  if (!queryParams.value.woche) setQueryParam("zeit", getMondayDate());
  const query = useGet(request, plan, queryParams);

  useValidation(plan, ZPlan);

  return {
    query,
    setQueryParam,
    queryParams,
    plan,
  };
};

export default useAbrechnungsplan;
