<script setup lang="ts">
import useClient from "@/api/client";
import useCommand from "@/composables/useCommand";
import { ButtonNormal, ModalUpdate } from "lorga-ui";
import { toRefs } from "vue";
import { useFahrer } from "../composables/useFahrer";
import useBegleitfahrtFields from "../composables/useBegleitfahrtFields";
import { useBegleitfahrtTypen } from "@/features/sonstiges/api/useBegleitfahrtTypen";

interface IFahrer {
  id: string;
  hat_dokumente: boolean;
  ist_informiert: boolean;
}

const props = defineProps<{
  query: () => void;
  auftragId: string;
  begleitfahrtId: string;
  typId: string;
  fahrer: IFahrer | null;
  von: string;
  nach: string;
  abholdatum: string | null;
  anlieferdatum: string | null;
  kennzeichen: string;
  mehrwertsteuerpflichtig: boolean;
}>();
const { auftragId, query, begleitfahrtId } = toRefs(props);

const client = useClient();

const { request } = client.post("/command/");
const { commandRequest, commandModalOpen } = useCommand(request, query.value);

const { fahrerForm } = useFahrer(commandModalOpen);

const { typenSelect } = useBegleitfahrtTypen(commandModalOpen);
const { begleitfahrtFields: fields } = useBegleitfahrtFields(
  typenSelect,
  fahrerForm,
);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">B</ButtonNormal>
  <ModalUpdate
    v-model="commandModalOpen"
    title="Begleitfahrt Bearbeiten"
    submit="Speichern"
    :fields="fields"
    :request="commandRequest"
    :data="{
      action: 'auftrag.begleitfahrt.bearbeiten',
      auftrag_id: auftragId,
      begleitfahrt_id: begleitfahrtId,
      fahrer: fahrer,
      von: von,
      typ: typId,
      nach: nach,
      abholdatum: abholdatum,
      anlieferdatum: anlieferdatum,
      kennzeichen: kennzeichen,
      mehrwertsteuerpflichtig: mehrwertsteuerpflichtig,
    }"
  />
</template>
